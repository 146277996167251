import React, { useEffect, useState} from "react";
import { Redirect, RouteProps } from "react-router-dom";
import logo from '../../assets/images/logo_b.svg';

import { useAppContext } from '../../contexts/app'
import queryString from 'query-string'
import LoadingOverlay from "../common/LoadingOverlay";

const getAuthUrl = (redirectPathName: string) => {
  const authSiteUrl = process.env.REACT_APP_AUTH_SITE_URL
  const callbackUrl = `${window.location.origin}#${redirectPathName}`
  return `${authSiteUrl}?callback=${window.encodeURIComponent(callbackUrl)}`
}

const getUserId = (search: string) => {
  const queryParams = queryString.parse(search);
  if (queryParams.ID instanceof Array) {
    return queryParams.ID[0]
  } else {
    return queryParams.ID
  }
}

const LoginPage : React.FC<RouteProps>  = (props: RouteProps) => {
  // https://github.com/slorber/react-async-hook
  const { authToken, login, } = useAppContext()
  const [loading, setLoading] = useState<boolean>(false);
  
  const userId = getUserId(window.location.search)
  const [tokenExpired, setTokenExpired] = React.useState<boolean>(false)

  let redirectToPathname = '/'
  if (props.location && props.location.state) {
    const state = props.location.state as any
    redirectToPathname = state.from.pathname
  }

  useEffect(() => {
    let unmounted = false;
    if (!authToken && userId) {
      setLoading(true);
      login(userId)
      .then(x => {
        // MARK: IDパラメータをURLから削除
        const newUrl = `${window.location.origin}#${redirectToPathname}`
        if (!unmounted) { 
          setLoading(false);
          window.location.href = newUrl
        }
      })
      .catch((x) => {
        setLoading(false);
        setTokenExpired(true);
      })
    };
    return () => {
      unmounted = true;
    }
  }, [authToken, userId, login, redirectToPathname])

  if (authToken) {
    return <Redirect to={{pathname: redirectToPathname}} />;
  }

  return <>
    <LoadingOverlay active={loading} />
    {
      tokenExpired && 
      <div className="alert alert-danger">認証の有効期限切れ</div>
    }
    <div className="box-login">
      <h1 className="logo"><img src={logo} alt="TAG WORK" className="w-100" /></h1>
      <button
        onClick={e => {
          e.preventDefault()
          const url = getAuthUrl(redirectToPathname)
          window.location.href = url
        }}
        className="btn btn-lg btn-primary btn-block">ログイン</button>
    </div>
  </>
}

export default LoginPage;
